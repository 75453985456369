@import "../../../assets/colors.scss";
.wrapper {
    .nav {
        width: 100%;
        height: 60px;
        background-color: $light-blue-4;
        display: flex;
        align-items: center;
        .maxWidthContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 16px;
                font-weight: 600;
                color: $text-black;
            }
            button {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-blue-5;
                color: $white;
                font-size: 22px;
            }
        }
    }
    .cardsContainer {
        margin-top: 30px;
        .maxWidthContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 15px;
            box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px $light-grey-2;
            background-color: $white;
            .card {
                width: 100%;
                padding: 15px;
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    p {
                        font-size: 16px;
                        font-weight: 600;
                        color: $text-black;
                    }
                    button {
                        width: 149px;
                        height: 32px;
                        border-radius: 5px;
                        background-color: $light-blue-4;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $blue;
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
                .modules {
                    display: flex;
                    .section {
                        &:first-child {
                            margin-right: 16px;
                        }
                        .module {
                            height: 32px;
                            display: flex;
                            align-items: center;
                            background-color: $light-brown;
                            border-radius: 5px;
                            color: $text-black;
                            margin-bottom: 10px;
                            padding-right: 16px;
                            svg {
                                stroke: $grey;
                                margin-left: 10px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .profileCard {
                width: 100%;
                border-radius: 15px;
                padding: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                .header {
                    display: flex;
                    align-items: center;
                    .image {
                        width: 88px;
                        height: 88px;
                        margin-right: 20px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                        }
                    }
                    .text {
                        p {
                            &:first-child {
                                font-size: 16px;
                                color: $text-black;
                                margin-bottom: 4px;
                            }
                            &:last-child {
                                font-size: 13px;
                                color: $grey;
                            }
                        }
                    }
                }
                .contact {
                    margin-top: 46px;
                    p {
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        color: $text-black;
                        margin-bottom: 8px;
                        svg {
                            margin-right: 10px;
                        }
                    }
                }
                .controller {
                    margin-top: 40px;
                    button {
                        width: 61px;
                        height: 28px;
                        background-color: $light-blue-4;
                        color: $blue;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .performanceWrapper {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1300px) {
    .maxWidthContainer {
        width: 95%;
    }
    .wrapper {
        .cardsContainer {
            .maxWidthContainer {
                .card,
                .profileCard {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .profileCard {
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .wrapper {
        .cardsContainer {
            .maxWidthContainer {
                .card,
                .profileCard {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .card {
                    .modules {
                        display: flex;
                        flex-direction: column;
                        .section {
                            &:first-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .profileCard {
                    height: auto;
                }
            }
        }
    }
}