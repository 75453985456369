.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;

    .card {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

        .logo {
            text-align: center;
            margin-bottom: 18px;
            padding-top: 10px;
            img {
                width: 200px;
                margin-left: 45px;
            }
        }
        form {
            display: flex;
            flex-direction: column;
            width: 450px;
            padding: 20px;
            padding-bottom: 30px;
            border-radius: 5px;

            label {
                font-size: 14px;
                color: #2a2a2a;
                font-weight: 500;
                margin-bottom: 8px;
            }
            input {
                height: 35px;
                margin-bottom: 16px;
                border-radius: 5px;
                border: 1px solid #bababa;
                padding: 0 10px;
            }
            .error {
                color: red;
                font-size: 14px;
                font-weight: 300;
            }
            button {
                width: 120px;
                height: 38px;
                background-color: #6a2c70;
                border-radius: 5px;
                font-size: 15px;
                color: #fff;
                margin-top: 10px;
                margin-left: auto;
            }
        }
    }
}
