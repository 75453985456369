@import "../../assets/colors.scss";
.wrapper {
    background-color: $light-grey-4;
    padding: 50px 0;
    .maxWidthContainer {
        max-width: 1300px;
        margin: 0 auto;
        .form {
            width: 100%;
            background-color: $white;
            padding: 20px 30px 20px 30px;
            border-radius: 15px;
            box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px #eee;
            .header {
                margin-bottom: 30px;
                p {
                    &:first-child {
                        font-size: 22px;
                        font-weight: 600;
                        color: $text-black;
                        margin-bottom: 8px;
                    }
                    &:last-child {
                        font-size: 14px;
                        color: $text-black;
                    }
                }
            }
            form {
                height: 180px;
                display: flex;
                flex-direction: column;
                .row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    .block {
                        width: 40%;
                        height: 80px;
                        &:first-child {
                            margin-right: 20px;
                        }
                        label {
                            display: block;
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 8px;
                            color: $grey;
                        }
                        p {
                            font-size: 12px;
                            color: #ff0044;
                            margin-top: 5px;
                        }
                    }
                }
                .controller {
                    margin-top: auto;
                    display: flex;
                    button {
                        &:first-child {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1320px) {
    .wrapper {
        .maxWidthContainer {
            width: 95%;
        }
    }
}

@media (max-width: 700px) {
    .wrapper {
        .maxWidthContainer {
            .form {
                form {
                    height: 300px;
                    .row {
                        flex-direction: column;
                        .block {
                            width: 100%;
                            height: auto;
                            margin-bottom: 16px;
                            &:first-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}