@import "../../../assets/colors.scss";
.wrapper {
    // max-width: 1300px;
    margin: 0 auto;
    .mapContainer {
        margin: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../assets/mapbg.png");
        padding: 350px 0 300px 0;
    }
    .statsContainer {
        .heading {
            font-size: 22px;
            font-weight: 600;
            color: $text-black;
            margin-bottom: 8px;
            margin-top: 70px;
        }
        .cards {
            margin-top: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    .searchForm {
        width: 100%;
        background-color: $light-grey-4;
        padding: 32px 0;
        margin-top: 50px;
    }
    .searchResult {
        margin-top: 50px;
        // margin-bottom: 50px;
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1320px) {
    .maxWidthContainer {
        width: 95%;
        .statsContainer {
            width: 100%;
            .cards {
                width: 100%;
            }
        }
    }
}