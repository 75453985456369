@import "../../assets/colors.scss";
.wrapper {
    width: 258px;
    height: 152px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 30px 30px 0;
    .icon {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }
    p {
        &:nth-child(2) {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 4px;
        }
        &:last-child {
            font-size: 14px;
        }
    }
}

.black {
    background-color: $light-grey-3;
    .icon {
        background-image: linear-gradient(to bottom right, $light-grey-3, rgba($text-black, 0.2));
        color: $text-black;
    }
    p {
        color: $text-black;
    }
}

.pink {
    background-color: $light-pink;
    .icon {
        background-image: linear-gradient(to bottom right, $light-pink-2, rgba($pink, 0.2));
        color: $pink;
    }
    p {
        color: $pink;
    }
}

.purple {
    background-color: $light-purple;
    .icon {
        background-image: linear-gradient(to bottom right, $light-purple-2, rgba($purple, 0.2));
        color: $purple;
    }
    p {
        color: $purple;
    }
}

.brown {
    background-color: $light-brown;
    .icon {
        background-image: linear-gradient(to bottom right, $light-brown, rgba($brown, 0.2));
        color: $brown;
    }
    p {
        color: $brown;
    }
}

.green {
    background-color: $light-green;
    .icon {
        background-image: linear-gradient(to bottom right, $light-green, rgba($dark-green, 0.2));
        color: $dark-green;
    }
    p {
        color: $dark-green;
    }
}

.blue {
    background-color: $light-blue;
    .icon {
        background-image: linear-gradient(to bottom right, $light-blue, rgba($blue, 0.2));
        color: $blue;
    }
    p {
        color: $blue;
    }
}

.olive {
    background-color: $light-olive;
    .icon {
        background-image: linear-gradient(to bottom right, $light-olive, rgba($olive, 0.2));
        color: $olive;
    }
    p {
        color: $olive;
    }
}

@media (max-width: 700px) {
    .wrapper {
        width: 100%;
    }
}