@import "../../assets/colors.scss";
.wrapper {
    background-color: $light-grey-4;
    padding: 50px 0;
    .heading {
        font-size: 22px;
        font-weight: 600;
        color: $text-black;
        margin-bottom: 8px;
    }
    .subHeading {
        font-size: 14px;
        color: $dark-green;
    }
    .school {
        margin-top: 36px;
        font-size: 16px;
        color: #2f2f2f;
        margin-bottom: 8px;
    }
    .location {
        color: rgba($color: #000000, $alpha: 0.5);
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1320px) {
    .maxWidthContainer {
        width: 95%;
    }
}