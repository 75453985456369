@import "../../../assets/colors.scss";
.wrapper {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px $light-grey-2;
    background-color: $white;
    border-radius: 15px;
    padding: 15px 15px 0 15px;
    min-width: 700px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .heading {
            font-size: 16px;
            font-weight: 600;
            color: $text-black;
        }
        .controllers {
            display: flex;
            align-items: center;
            form {
                margin-right: 20px;
                .searchBox {
                    width: 308px;
                    background-color: $light-blue-2;
                    display: flex;
                    align-items: center;
                    height: 32px;
                    border-radius: 5px;
                    padding: 5px;
                    .icon {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            stroke: $blue;
                        }
                    }
                    input {
                        width: 100%;
                        height: 100%;
                        border: none;
                        background-color: transparent;
                    }
                }
            }
            button {
                width: 32px;
                height: 32px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-blue-2;
                color: $blue;
                &:last-child {
                    margin-left: 20px;
                }
            }
        }
    }
    .table {
        width: 100%;
        height: 400px;
        overflow: auto;
        table {
            width: 100%;
            thead {
                background-color: $light-blue-3;
                display: block;
                tr {
                    width: 100%;
                    height: 47px;
                    display: table;
                    table-layout: fixed;
                    td {
                        padding: 5px 0 5px 25px;
                        font-size: 13px;
                        font-weight: 600;
                        color: $grey;
                        border-right: 1px solid $light-grey-5;
                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
            tbody {
                width: 100%;
                display: block;
                tr {
                    width: 100%;
                    height: 47px;
                    display: table;
                    table-layout: fixed;
                    td {
                        padding: 5px 0 5px 25px;
                        font-size: 13px;
                        color: $text-black;
                        border-right: 1px solid $light-grey-5;
                        &:last-child {
                            border: none;
                        }
                        button {
                            width: 62px;
                            height: 28px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 11px;
                            font-weight: 600;
                            color: $blue;
                            background-color: $light-blue-2;
                            svg {
                                width: 18px;
                                height: 18px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .table {
        height: 100%;
    }
}