/* VARIABLE NAMING CONVENTION : $color-shade-level
    eg - $grey-light-5: #d4d4d4;
*/

$grey: #777777;
$light-grey: #afafaf;
$light-grey-2: #eeeeee;
$light-grey-3: #f2f2f2;
$light-grey-4: #f1f1f1;
$light-grey-5: #d4d4d4;
$grey-light-6: #f9f9f9;
$black: #000000;
$white: #ffffff;
$text-black: #2f2f2f;
$dark-green: #005249;
$light-green: #c8facd;
$light-pink: #fee9e5;
$light-pink-2: #ffdddd;
$pink: #cb3939;
$purple: #7719b5;
$bright-purple: #7a0ed9;
$light-purple: #f0d9ff;
$light-purple-2: #e9c9ff;
$brown: #7a4f01;
$light-brown: #fff7cd;
$blue: #04297a;
$light-blue: #d0f2ff;
$light-blue-2: #edf5ff;
$light-blue-3: #f4f6f8;
$light-blue-4: #dae4f2;
$light-blue-5: #90a8c9;
$olive: #5c8636;
$light-olive: #edf6e5;
$orange: #ff8000;
