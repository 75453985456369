.wrapper {
    .nav {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dae4f2;
        button {
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            font-weight: 500;
            svg {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
        }
    }
    .header {
        margin-top: 50px;
        margin-bottom: 20px;
        p {
            font-size: 24px;
            font-weight: 600;
            color: #2f2f2f;
        }
    }
    .table {
        overflow-x: auto;
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1320px) {
    .maxWidthContainer {
        width: 95%;
    }
}