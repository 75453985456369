@import "../../../assets/colors.scss";
.wrapper {
    .nav {
        height: 60px;
        background-color: $light-blue-4;
        display: flex;
        align-items: center;
        .maxWidthContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .backButton {
            font-size: 16px;
            font-weight: 600;
            color: $text-black;
            svg {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }
        .downloadReport {
            font-size: 13px;
            color: $grey;
            svg {
                width: 20px;
                height: 20px;
                stroke: $blue;
                margin-right: 5px;
            }
        }
    }
    .header {
        height: 108px;
        background-color: $grey-light-6;
        display: flex;
        align-items: center;
        .maxWidthContainer {
            display: flex;
            align-items: center;
        }
        .info {
            margin-right: 80px;
            p {
                &:first-child {
                    font-size: 14px;
                    color: $grey;
                    margin-bottom: 4px;
                }
                &:last-child {
                    font-size: 15px;
                    font-weight: 600;
                    color: $text-black;
                }
            }
        }
    }
    .reportCards {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .card {
            margin-right: 28px;
            margin-bottom: 28px;
            padding: 24px;
            width: 250px;
            height: 152px;
            display: flex;
            flex-direction: column;
            background-size: cover;
            .statNumber {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 4px;
            }
            .statName {
                font-size: 14px;
            }
            .icon {
                margin-left: auto;
                margin-top: 12px;
                width: 44px;
                height: 44px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.purple {
    background-image: linear-gradient(to top left, #f0d9ff, rgba(240, 217, 255, 0));
    background-image: url("../../../assets/report/enrolled.png");
    .statNumber,
    .statName {
        color: $purple;
    }
    .icon {
        background-image: linear-gradient( to bottom right, $light-purple-2, rgba($purple, 0.2));
        color: $purple;
    }
}

.brown {
    background-image: linear-gradient(to top left, #fff4ba, #fffbe5);
    background-image: url("../../../assets/report/progress.png");
    .statNumber,
    .statName {
        color: $brown;
    }
    .icon {
        background-image: linear-gradient(to bottom right, $light-brown, rgba($brown, 0.2));
        color: $brown;
    }
}

.green {
    background-image: linear-gradient(to top left, #c8facd, rgba(200, 250, 205, 0));
    background-image: url("../../../assets/report/completed_bg.png");
    .statNumber,
    .statName {
        color: $dark-green;
    }
    .icon {
        background-image: linear-gradient(to bottom right, $light-green, rgba($dark-green, 0.2));
        color: $dark-green;
    }
}

.pink {
    background-image: linear-gradient(to top left, #fee9e5, rgba(254, 233, 229, 0));
    background-image: url("../../../assets/report/not-started.png");
    .statNumber,
    .statName {
        color: $pink;
    }
    .icon {
        background-image: linear-gradient(to bottom right, $light-pink-2, rgba($pink, 0.2));
        color: $pink;
    }
}

.blue {
    background-image: linear-gradient(to top left, #d0f2ff, rgba(208, 242, 255, 0));
    background-image: url("../../../assets/report/percentage.png");
    .statNumber,
    .statName {
        color: $blue;
    }
    .icon {
        background-image: linear-gradient(to bottom right, $light-blue, rgba($blue, 0.2));
        color: $blue;
    }
}

.chart {
    background-image: linear-gradient(to top left, #edf6e5, rgba(237, 246, 229, 0));
    background-image: url("../../../assets/report/accuracy.png");
    &:last-child {
        background-image: url("../../../assets/report/speed.png");
    }
    .statName {
        font-size: 14px;
        color: $text-black;
    }
    .donut {
        margin-top: 16px;
        width: 75px;
        height: 75px;
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1320px) {
    .maxWidthContainer {
        width: 95%;
    }
}

@media (max-width: 700px) {
    .wrapper {
        .header {
            height: auto;
            padding: 16px;
            .maxWidthContainer {
                flex-direction: column;
                align-items: flex-start;
            }
            .info {
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
        .reportCards {
            .card {
                width: 100%;
                margin-right: 0;
                background-position: bottom;
            }
        }
    }
}