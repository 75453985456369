.wrapper {
    width: 1300px;
    margin-top: 80px;
    margin: 0 auto;
    margin-top: 50px;
    h1 {
        font-size: 18px;
        font-weight: 500;
        color: #2f2f2f;
        margin-bottom: 24px;
    }
    .grid {
        display: flex;
        >div {
            width: 50%;
            max-height: 573px;
        }
        .column {
            .accordion {
                max-height: 84px;
                overflow: hidden;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
                margin-bottom: 2px;
                transition: all 0.2s ease;
                .header {
                    display: flex;
                    align-items: center;
                    padding: 20px 15px;
                    cursor: pointer;
                    div {
                        min-width: 60px;
                        p {
                            &:first-child {
                                font-size: 12px;
                                color: #666;
                            }
                            &:last-child {
                                font-size: 14px;
                                font-weight: bold;
                                color: #000;
                            }
                        }
                        &:first-child {
                            margin-right: auto;
                            p {
                                &:last-child {
                                    font-size: 14px;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-right: 80px;
                        }
                        &:last-child {
                            background-color: #eee;
                            min-width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            svg {
                                stroke: #999;
                            }
                        }
                    }
                }
                .report {
                    padding: 20px 15px;
                    .titles {
                        display: flex;
                        margin-bottom: 15px;
                        p {
                            font-size: 12px;
                            color: #666;
                            min-width: 60px;
                            &:first-child {
                                margin-right: auto;
                            }
                            &:nth-child(2) {
                                margin-right: 80px;
                            }
                            &:nth-child(3) {
                                margin-right: 120px;
                            }
                        }
                    }
                    .dataRow {
                        display: flex;
                        justify-content: flex-start;
                        padding: 15px 0;
                        border-bottom: 1px solid #d4d4d4;
                        &:last-child {
                            border-bottom: 0;
                            padding-bottom: 0;
                        }
                        p {
                            font-size: 14px;
                            color: #000;
                            min-width: 60px;
                            &:first-child {
                                margin-right: auto;
                            }
                            &:nth-child(2) {
                                margin-right: 80px;
                            }
                            &:nth-child(3) {
                                margin-right: 120px;
                            }
                        }
                    }
                }
                .blueTheme {
                    div {
                        &:first-child {
                            p {
                                &:last-child {
                                    color: #1176ae;
                                }
                            }
                        }
                    }
                }
                .redTheme {
                    div {
                        &:first-child {
                            p {
                                &:last-child {
                                    color: #ea4335;
                                }
                            }
                        }
                    }
                }
                .orangeTheme {
                    div {
                        &:first-child {
                            p {
                                &:last-child {
                                    color: #f46c30;
                                }
                            }
                        }
                    }
                }
                .purpleTheme {
                    div {
                        &:first-child {
                            p {
                                &:last-child {
                                    color: #6a2c70;
                                }
                            }
                        }
                    }
                }
                .greenTheme {
                    div {
                        &:first-child {
                            p {
                                &:last-child {
                                    color: #34a853;
                                }
                            }
                        }
                    }
                }
            }
            .accordionOpen {
                max-height: 500px;
            }
        }
    }
    .graphWrapper {
        position: relative;
        .selectWrapper {
            position: absolute;
            left: 15px;
            top: 15px;
            width: 300px;
        }
        .legendWrapper {
            position: absolute;
            left: 15px;
            bottom: 10px;
            .legendItem {
                display: flex;
                align-items: center;
                margin: 5px 0;
                font-size: 13px;
                .box {
                    width: 30px;
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }
        .chartWrapper {
            width: 100%;
            height: 300px;
        }
        margin-left: 20px;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
        padding: 50px;
        padding-top: 100px;
        padding-bottom: 150px;
    }
}

@media (max-width: 1310px) {
    .wrapper {
        width: 95%;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        .grid {
            flex-direction: column;
            width: 100%;
            >div {
                width: 100%;
                margin: 0;
                max-height: unset;
                &:last-child {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        .grid {
            .graphWrapper {
                padding: 50px 5px;
            }
        }
    }
}

@media (max-width: 700px) {
    .wrapper {
        .grid {
            .column {
                .accordion {
                    .header {
                        padding: 20px 10px;
                        align-items: flex-start;
                        div {
                            &:nth-child(2),
                            &:nth-child(3) {
                                margin-right: 20px;
                            }
                            &:last-child {
                                min-width: 25px;
                                height: 25px;
                            }
                        }
                    }
                    .report {
                        .titles {
                            p {
                                &:nth-child(2) {
                                    margin-right: 40px;
                                }
                                &:nth-child(3) {
                                    margin-right: 20px;
                                }
                            }
                        }
                        .dataRow {
                            p {
                                &:first-child {
                                    max-width: 100px;
                                }
                                &:nth-child(2) {
                                    margin-right: 40px;
                                }
                                &:nth-child(3) {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .graphWrapper {
            height: 600px;
            .chartWrapper {
                margin-top: 50px;
            }
        }
    }
}