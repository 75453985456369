@import "../../assets/colors.scss";
.wrapper {
    border-bottom: 1px solid $light-grey-2;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    .maxWidthContainer {
        width: 1300px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
            }
        }
        .heading {
            font-size: 16px;
            font-weight: 500;
            color: $light-grey;
        }
        .controllers {
            .user {
                cursor: pointer;
                p {
                    font-size: 14px;
                    color: $black;
                }
                button {
                    padding: 10px;
                    background-color: $orange;
                    color: white;
                    width: 100px;
                    border-radius: 5px;
                }
            }
        }
    }
}

@media (max-width: 1320px) {
    .wrapper {
        .maxWidthContainer {
            width: 95%;
        }
    }
}