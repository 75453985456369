@import "../../../assets/colors.scss";
.wrapper {
    .nav {
        height: 60px;
        background-color: $light-blue-4;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        .maxWidthContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .backButton {
            font-size: 16px;
            font-weight: 600;
            color: $text-black;
            svg {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }
        .downloadReport {
            font-size: 13px;
            color: $grey;
            svg {
                width: 20px;
                height: 20px;
                stroke: $blue;
                margin-right: 5px;
            }
        }
    }
    .section {
        padding-bottom: 20px;
        border-bottom: 1px solid #eaeaea;
        margin-top: 20px;
        .title {
            margin-bottom: 16px;
            p {
                &:first-child {
                    font-size: 14px;
                    color: #777;
                    margin-bottom: 4px;
                }
                &:last-child {
                    font-size: 16px;
                    font-weight: 600;
                    color: #2f2f2f;
                }
            }
        }
        .stats {
            display: flex;
            div {
                width: 200px;
                height: 70px;
                padding: 16px;
                p {
                    &:first-child {
                        font-weight: 600;
                        margin-bottom: 6px;
                    }
                    &:last-child {
                        font-size: 14px;
                    }
                }
                &:first-child {
                    background-image: linear-gradient(to top left, #f0d9ff, #fcf8ff);
                    p {
                        color: #7719b5;
                    }
                }
                &:nth-child(2) {
                    background-image: linear-gradient(to top left, #d0f2ff, #fcf8ff);
                    p {
                        color: #04297a;
                    }
                }
                &:nth-child(3) {
                    background-image: linear-gradient(to top left, #edf6e5, #fcf8ff);
                    p {
                        color: #263238;
                    }
                }
                &:last-child {
                    background-image: linear-gradient(to top left, #edf6e5, #fcf8ff);
                    p {
                        color: #263238;
                    }
                }
            }
        }
    }
}

.maxWidthContainer {
    width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1320px) {
    .maxWidthContainer {
        width: 95%;
    }
}

@media (max-width: 900px) {
    .wrapper {
        .section {
            .stats {
                flex-direction: column;
                div {
                    width: 100%;
                }
            }
        }
    }
}